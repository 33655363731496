/* eslint-disable @next/next/no-img-element */
'use client';
import Marquee from 'react-fast-marquee';

import { PartnerType } from '@/src/domain/partners/PartnerType';

import styles from './Partners.module.scss';

const PartnersSection = ({ data }: { data: PartnerType[] }) => {
  return (
    <Marquee className={styles.partners_list} pauseOnHover speed={40} gradient={false} play={true}>
      {data?.map(({ link, image, id, title, is_follow }) => (
        <a
          className={styles.partners_list__item}
          key={id}
          href={link}
          target="_blank"
          rel={`noreferrer ${is_follow ? 'follow' : 'nofollow'}`}
        >
          <div className={styles.partners__link}>
            <img src={image} alt={title} loading="lazy" />
          </div>
        </a>
      ))}
    </Marquee>
  );
};

export default PartnersSection;
